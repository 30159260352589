;(function () {
  'use strict'

  angular.module('ottomatikStoreManager').directive('noSubmitOnEnter', noSubmitOnEnterDirective)

  function noSubmitOnEnterDirective() {
    return {
      link: link,
      require: 'ngModel',
      restrict: 'A',
    }

    function link(scope, element, attrs, ngModel) {
      var elements = [element[0]]

      if (element[0].tagName.toLowerCase() != 'input') {
        elements = Array.from(element[0].getElementsByTagName('input'))
      }

      elements.forEach((e) => e.addEventListener('keydown', preventEnter))
    }

    function preventEnter(event) {
      if (event.key == 'Enter') {
        void 0
        event.preventDefault()
        return false
      }
    }
  }
})()
