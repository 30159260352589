(function () {
  'use strict'

  angular.module('ottomatikStoreManager.user', [])
    .config(UserConfig)
    .factory('UserService', UserService)
    .controller('LoginController', LoginController)
    .controller('LoginYubiKeyController', LoginYubiKeyController)
    .controller('PasswordForgotController', PasswordForgotController)
    .controller('PasswordResetController', PasswordResetController)
    .controller('AccountController', AccountController)
    .controller('AccountEditController', AccountEditController)
    .controller('AccountYubiKeyController', AccountYubiKeyController)

  function UserConfig ($stateProvider) {
    $stateProvider.state('user', {
      url: '/user',
      abstract: true,
    })

    //
    // PUBLIC
    //
    $stateProvider.state('user.login', {
      url: '/login',
      data: {
        public: true,
      },
      views: {
        '@': {
          templateUrl: 'src/user/views/login.html',
          controller: LoginController,
        },
      },
    }).state('user.login.yubikey', {
      url: '/yubikey',
      views: {
        '@': {
          templateUrl: 'src/user/views/login-yubikey.html',
          controller: LoginYubiKeyController,
        },
      },
      params: {
        types: { value: undefined },
        userId: { value: undefined },
        remember: { value: undefined },
      },
    }).state('user.password', {
      url: '/password',
      data: {
        public: true,
      },
      redirectTo: 'user.password.forgot'
    }).state('user.password.forgot', {
      url: '',
      views: {
        '@': {
          templateUrl: 'src/user/views/password-forgot.html',
          controller: PasswordForgotController,
        },
      },
      params: {
        email: { value: undefined }
      },
    }).state('user.password.reset', {
      url: '/reset',
      views: {
        '@': {
          templateUrl: 'src/user/views/password-reset.html',
          controller: PasswordResetController,
        },
      },
      params: {
        email: { value: undefined }
      },
    })

    //
    // PRIVATE
    //
    $stateProvider.state('user.edit', {
      url: '/edit',
      views: {
        '@': { templateUrl: 'src/user/views/layout.html' },
        'colMain@user.edit': {
          templateUrl: 'src/user/views/edit.html',
          controller: AccountEditController,
        },
      },
    }).state('user.yubikey', {
      url: '/yubikey',
      views: {
        '@': { templateUrl: 'src/user/views/layout.html' },
        'colMain@user.yubikey': {
          templateUrl: 'src/user/views/yubikey.html',
          controller: AccountYubiKeyController,
        },
      },
    })
  }

  function UserService($cookies, $resource, $rootScope, $state, $window, CONFIG) {
    var api = {
      app: {
        kewk: $resource(CONFIG.BASE_URL + '/application/kewk/:type').get
      },
      user: $resource(CONFIG.BASE_URL + '/user/:action', {}, {
        login: { method: 'POST', params: { action: 'login' } },
        logout: { method: 'POST', params: { action: 'logout' } },
        forgotPassword: { method: 'POST', params: { action: 'forgot-password' } },
        resetPassword: { method: 'POST', params: { action: 'reset-password' }, isArray: true },
        changeEmail: { method: 'POST', params: { action: 'change-email' }, isArray: true },
        changePassword: { method: 'POST', params: { action: 'change-password' }, isArray: true },
        sendMail: { method: 'POST', params: { action: 'sendmail' }, isArray: true },
      }),
      yubikey: $resource(CONFIG.BASE_URL + '/user/yubikey'),
      u2f: $resource(CONFIG.BASE_URL + '/user/u2f/:action', {}, {
        register: { method: 'POST', params: { action: 'register' } },
        authenticate: { method: 'POST', params: { action: 'authenticate' } },
      }),
    }

    var service = {
      login: login,
      loginOTP: loginOTP,
      logout: logout,
      loginSuccess: loginSuccess,
      getRedirect: getRedirect,
      getRedirectAfterLogin: getRedirectAfterLogin,
      setRedirectAfterLogin: setRedirectAfterLogin,

      forgotPassword: forgotPassword,
      resetPassword: resetPassword,

      editEmail: editEmail,
      editPassword: editPassword,

      getUser: getUser,
      getCustomerId: getCustomerId,
      getRoles: getRoles,
      hasRole: hasRole,

      killConnections: killConnections,
      filterCustomerSystems: filterCustomerSystems,
      sendMail: sendMail,

      getYubiKeys: getYubiKeys,
      addYubiKey: addYubiKey,
      delYubiKey: delYubiKey,

      u2fRegister: u2fRegister,
      u2fAuthenticate: u2fAuthenticate,
    }

    return service

    function login (email, password, remember) {
      return api.user.login({ identity: email, credential: password, remember: remember }).$promise
    }

    function loginOTP (otp, isTwofactor, remember) {
      return api.user.login({ otp: otp, isTwofactor: isTwofactor, remember: remember }).$promise
    }

    function logout() {
      return api.user.logout().$promise.finally(function () {
        $window.location.href = '/'
      })
    }

    function loginSuccess () {
      getUser()
        .then(function () {
          $state.go('index')
        })
    }

    function getRedirect () {
      var redirect = $cookies.getObject('redirect')
      $cookies.remove('redirect')
      return redirect
    }

    function getRedirectAfterLogin () {
      var redirect = this.redirectAfterLogin
      delete this.redirectAfterLogin
      return redirect
    }

    function setRedirectAfterLogin (state, params) {
      var cookie = $cookies.getObject('redirectAfterLogin')
      if (cookie) {
        $cookies.remove('redirectAfterLogin')
        this.redirectAfterLogin = cookie
        return
      }
      this.redirectAfterLogin = { state: state.name, params: params }
    }

    function forgotPassword (email) {
      return api.user.forgotPassword({ identity: email }).$promise
    }

    function resetPassword (password, verify) {
      return api.user.resetPassword({ password: password, verify: verify }).$promise
    }

    function editEmail (email, verify, credential) {
      return api.user.changeEmail({
        email: email,
        verify: verify,
        credential: credential,
      }).$promise
    }

    function editPassword (password, verify, credential) {
      return api.user.changePassword({
        password: password,
        verify: verify,
        credential: credential,
      }).$promise
    }

    function getUser () {
      return api.user.get().$promise.then(
        function (response) {
          $rootScope.globals.currentUser = response.user
          return response.user
        }
      )
    }

    function getCustomerId () {
      var currentUser = $rootScope.globals.currentUser
      if (!currentUser) {
        return 0
      }
      return currentUser.customerId
    }

    function getRoles () {
      var currentUser = $rootScope.globals.currentUser
      if (!currentUser) {
        return []
      }
      // NEW: return angular.copy(currentUser.roles)
      return currentUser.roles.reduce((acc, role) => {
        acc.push(role.name || role)
        return acc
      }, [])
    }

    function hasRole (array) {
      var currentUser = $rootScope.globals.currentUser
      if (!currentUser) {
        return false
      }

      if (!angular.isArray(array)) {
        array = [array]
      }

      // NEW: return array.some((name) => currentUser.roles.includes(name))
      return array.some((name) => currentUser.roles.findIndex((role) => (role.name || role) === name) > -1)
    }

    function killConnections (type) {
      return api.app.kewk({ type: type }).$promise
    }

    function filterCustomerSystems (customer) {
      var systemIds = $rootScope.globals.currentUser.systems
      customer.customerSystemAssociations = customer.customerSystemAssociations.filter(function (csa) {
        return (
          systemIds.includes(0) /* alle */ ||
          (systemIds.includes(-1) && csa.system.origin.startsWith('ottomatik_')) /* alle internen */ ||
          (systemIds.includes(-2) && !csa.system.origin.startsWith('ottomatik_')) /* alle externen */ ||
          systemIds.includes(csa.system.systemId) /* einzel */
        )
      })
      return customer.customerSystemAssociations
    }

    function sendMail (subject, message) {
      return api.user.sendMail({ subject: subject, message: message }).$promise
    }

    function getYubiKeys () {
      return api.yubikey.query().$promise
    }

    function addYubiKey (keyData) {
      return api.yubikey.save(keyData).$promise
    }

    function delYubiKey (yubikey) {
      return api.yubikey.delete({ yubikey: yubikey }).$promise
    }

    function u2fRegister (response) {
      if (response) {
        return api.u2f.register(response).$promise
      } else {
        return api.u2f.register().$promise
      }
    }

    function u2fAuthenticate (response) {
      return api.u2f.authenticate(response).$promise
    }
  }

  //
  // Login
  //

  function LoginController ($scope, $state, $cookies, UserService) {
    var flashMessages = $cookies.getObject('flashMessages')
    $cookies.remove('flashMessages')

    $scope.form = {
      error: flashMessages ? flashMessages.join('<br>') : false,
      email: '',
      password: '',
      remember: false,
      submit: function () {
        if ($scope.loading) {
          return
        }
        $scope.loading = true
        $scope.form.error = false
        var email = this.email
        if (!email.includes('@')) {
          email += '@ottomatik.de'
        }
        var remember = this.remember
        UserService.login(email, this.password, remember)
          .then(function (response) {
            if (response.twofactor) {
              return $state.go(
                'user.login.yubikey',
                {
                  types: response.twofactor,
                  userId: response.userId,
                  remember: remember,
                }
              )
            }
            UserService.loginSuccess()
          })
          .catch(function (error) {
            if (error.status === 401) {
              $scope.form.error = error.data.messages.join('<br>')
            }
          })
          .finally(function () {
            $scope.loading = false
          })
      },
      passwordReset: function () {
        $state.go('user.password.forgot', { email: $scope.form.email })
      },
      loginOTP: function () {
        $state.go('user.login.yubikey', { types: ['OTP'] })
      },
    }
  }

  function PasswordForgotController ($scope, $state, $transition$, $mdDialog, UserService) {
    $scope.form = {
      email: $transition$.params().email || '',
      submit: function () {
        if ($scope.loading) {
          return
        }
        $scope.loading = true
        UserService.forgotPassword(this.email)
          .then(function (response) {
            $mdDialog.show(
              $mdDialog.alert()
                .title('Passwort vergessen?')
                .htmlContent('<p class="center">Wir haben eine E-Mail an<br><b>' + response.email + '</b><br>mit weiteren Anweisungen gesendet.</p>')
                .ok('Verstanden')
            ).then(function () {
              $state.go('user.login')
            })
          })
          .finally(function () {
            $scope.loading = false
          })
      },
      cancel: function () {
        $state.go('user.login')
      },
    }
  }

  function PasswordResetController ($scope, $state, $transition$, UserService) {
    if (!$transition$.params().email) {
      $state.go('user.login')
    }
    $scope.email = $transition$.params().email
    $scope.form = {
      password: '',
      verify: '',
      submit: function () {
        if ($scope.loading) {
          return
        }
        $scope.loading = true
        UserService.resetPassword(this.password, this.verify)
          .then(function () {
            $state.go('user.login')
          })
          .finally(function () {
            $scope.loading = false
          })
      },
      cancel: function () {
        $state.go('user.login')
      },
    }
  }

  //
  // Account
  //

  function AccountController ($state, UserService) {
    var self = this

    self.edit = function () {
      $state.go('user.edit')
    }

    self.logout = function () {
      UserService.logout()
    }

    self.yubikey = function () {
      $state.go('user.yubikey')
    }
  }

  function AccountEditController ($rootScope, $scope, $state, UserService) {
    $scope.user = $rootScope.globals.currentUser

    $scope.emailForm = {
      model: {
        email: '',
        emailVerify: '',
        passwordCurrent: '',
      },
      submit: function () {
        var email = this.model.email
        var emailVerify = this.model.emailVerify
        var passwordCurrent = this.model.passwordCurrent
        UserService.editEmail(email, emailVerify, passwordCurrent)
          .then(function () {
            $scope.emailForm.model = {
              email: '',
              emailVerify: '',
              passwordCurrent: '',
            }
            $scope.userEmailForm.$setPristine()
            $scope.userEmailForm.$setUntouched()
            $scope.user.email = email
            UserService.getUser()
          })
      },
    }

    $scope.passwordForm = {
      model: {
        password: '',
        passwordVerify: '',
        passwordCurrent: '',
      },
      submit: function () {
        var password = this.model.password
        var passwordVerify = this.model.passwordVerify
        var passwordCurrent = this.model.passwordCurrent
        UserService.editPassword(password, passwordVerify, passwordCurrent)
          .then(function () {
            $scope.passwordForm.model = {
              password: '',
              passwordVerify: '',
              passwordCurrent: '',
            }
            $scope.userPasswordForm.$setPristine()
            $scope.userPasswordForm.$setUntouched()
          })
      },
    }
  }

  //
  // YubiKey
  //

  function LoginYubiKeyController ($interval, $scope, $state, $transition$, Notification, UserService, YubicoService) {
    var self = this
    var keyTypes = $transition$.params().types
    var userId = $transition$.params().userId
    var remember = $transition$.params().remember

    if (!keyTypes) {
      $state.go('user.login')
      return
    }

    $scope.$on('$destroy', function () {
      if (self.focus) {
        $interval.cancel(self.focus)
        self.focus = undefined
      }
    })

    var isTwofactor = Boolean(keyTypes.some(
      function (type) { return type.endsWith('2F') }
    ))

    void 0

    $scope.touchOk = undefined
    $scope.form = {
      error: false,
      otp: '',
      submit: function () {
        if (!$scope.isOTP) {
          return
        }
        var result = YubicoService.parseOTP(this.otp)
        void 0
        if (!result) {
          $scope.touchOk = false
          this.otp = ''
          Notification.warning({
            title: 'YubiKey',
            message: 'wurde nicht richtig erkannt.<br>Versuchen Sie es nochmal.',
          })
          return
        }
        $scope.touchOk = undefined
        this.error = false
        UserService.loginOTP(result.otp, isTwofactor, remember)
          .then(function (response) {
            if (response.authenticated === true) {
              $scope.touchOk = true
              UserService.loginSuccess()
            }
          })
          .catch(function (error) {
            $scope.touchOk = false
            if (error.status === 401) {
              $scope.form.error = error.data.messages.join('<br>')
            }
          })
          .finally(function () {
            $scope.form.otp = ''
          })
      },
      cancel: function () {
        $state.go('user.login')
      },
    }

    self.requestU2F = function () {
      if (!userId) {
        $state.go('user.login')
        return
      }
      void 0
      $scope.isU2F = true
      $scope.isOTP = false
      UserService.u2fAuthenticate({ userId: userId })
        .then(function (response) {
          void 0
          var reqs = response.reqs
          u2fApi.sign(reqs)
            .then(function (data) {
              void 0
              UserService.u2fAuthenticate({ userId: userId, data: data, remember: remember })
                .then(function (response) {
                  if (response.authenticated === true) {
                    $scope.touchOk = true
                    UserService.loginSuccess()
                  }
                })
                .catch(function (error) {
                  $scope.touchOk = false
                  if (error.status === 401) {
                    $scope.form.error = error.data.messages.join('<br>')
                  }
                })
            })
            .catch(function (data) {
              void 0
              $scope.$apply(function () {
                $scope.touchOk = false
              })
            })
        })
        .catch(function () {
          $scope.touchOk = false
        })
    }

    self.requestOTP = function () {
      void 0
      $scope.isU2F = false
      $scope.isOTP = true
      $scope.form.otp = ''
      self.focus = $interval(function () {
        document.loginYubiKeyForm.otp.focus()
      }, 100)
    }

    if (keyTypes.includes('U2F')) {
      u2fApi.isSupported()
        .then(function (supported) {
          void 0
          if (supported) {
            self.requestU2F()
          } else {
            self.requestOTP()
          }
        })
    } else {
      self.requestOTP()
    }
  }

  function AccountYubiKeyController ($scope, $mdDialog, UserService) {
    var self = this

    $scope.yubikeys = []
    UserService.getYubiKeys()
      .then(function (yubikeys) {
        $scope.yubikeys = yubikeys
      })

    $scope.add = function (event) {
      // STEP 1 - 1F or 2F
      $mdDialog.show({
        templateUrl: 'src/user/views/yubikey-dialog1.html',
        clickOutsideToClose: true,
        focusOnOpen: false,
        controller: YubiKeyDialogController,
        bindToController: true,
        locals: { step: 1 },
        targetEvent: event,
      }).then(function (choice) {
        // STEP 2 - confirm with touch
        var isTwofactor = choice === 2
        return $mdDialog.show({
          templateUrl: 'src/user/views/yubikey-dialog2.html',
          clickOutsideToClose: true,
          controller: YubiKeyDialogController,
          bindToController: true,
          locals: { step: 2, isTwofactor: isTwofactor },
          targetEvent: event,
        })
      }).then(function (keyData) {
        // STEP 3 - give it a name
        return $mdDialog.show({
          templateUrl: 'src/user/views/yubikey-dialog3.html',
          clickOutsideToClose: true,
          controller: YubiKeyDialogController,
          bindToController: true,
          locals: { step: 3, keyData: keyData },
          targetEvent: event,
        })
      }).then(function (keyData) {
        // RESULT - call backend
        if (keyData.u2f) {
          return UserService.u2fRegister(keyData)
        } else {
          return UserService.addYubiKey(keyData)
        }
      }).then(function (response) {
        // RESPONSE - from backend
        if (response && response.createdAt) {
          $scope.yubikeys.push(response)
        }
      }).catch(function (data) {
        // CATCH ERRORS
        if (data && data.u2f) {
          var code = data.u2f.code
          self.handleErrorCode(code)
        }
      })
    }

    self.handleErrorCode = function (errorCode) {
      var title = 'Achtung'
      var content = 'Ein Fehler ist aufgetreten!'
      var ok = 'Okay'
      var errorName
      if (u2fApi && u2fApi.ErrorNames) {
        errorName = u2fApi.ErrorNames[errorCode]
      }
      switch (errorCode) {
        case 1:
        case 2:
        case 3:
          if (typeof errorName !== 'undefined') {
            content += ' (Info: ' + errorName + ')'
          } break
        case 4: content = 'Der YubiKey ist bereits mit Ihrem Konto verknüpft!'; break
        case 5: content = 'Die Zeit zur YubiKey-Bestätigung ist abgelaufen!'; break
      }
      $mdDialog.show(
        $mdDialog.alert()
          .clickOutsideToClose(true)
          .title(title)
          .ariaLabel(title)
          .textContent(content)
          .ok(ok)
      )
    }

    $scope.delete = function (yubikey) {
      UserService.delYubiKey(yubikey.id)
        .then(function () {
          var index = $scope.yubikeys.indexOf(yubikey)
          $scope.yubikeys.splice(index, 1)
        })
    }
  }

  function YubiKeyDialogController ($scope, $mdDialog, $interval, Notification, UserService, YubicoService) {
    var self = this
    var msWait = 1000
    self.step = self.locals && self.locals.step ? self.locals.step : 0
    self.intervals = [] // store $intervals to clear them on $destroy

    $scope.$on('$destroy', function () {
      for (var i = 0, len = self.intervals.length; i < len; i++) {
        $interval.cancel(self.intervals[i])
      }
    })

    $scope.cancel = function () {
      $mdDialog.cancel()
    }

    void 0

    // dialog 1: choose 1F or 2F
    if (self.step === 1) {
      $scope.choose = function (choice) {
        void 0
        $mdDialog.hide(choice)
      }
    }

    // dialog 2: request U2F and/or OTP
    if (self.step === 2) {
      self.keyData = { isTwofactor: self.locals.isTwofactor }

      $scope.touchOk = undefined
      $scope.form = {
        otp: '',
        submit: function () {
          if (!$scope.isOTP) {
            return
          }
          var result = YubicoService.parseOTP(this.otp)
          void 0
          if (!result) {
            $scope.touchOk = false
            this.otp = ''
            Notification.warning({
              title: 'YubiKey',
              message: 'wurde nicht richtig erkannt.<br>Versuchen Sie es nochmal.',
            })
            $scope.again = true
            return
          }
          $scope.touchOk = true
          self.keyData.otp = { error: false, data: result.otp }
          $interval(function () {
            $mdDialog.hide(self.keyData)
          }, msWait, 1)
        },
      }

      self.requestU2F = function () {
        void 0
        $scope.isU2F = true
        $scope.isOTP = false
        UserService.u2fRegister()
          .then(function (register) {
            void 0
            var req = register.req
            var sigs = register.sigs
            u2fApi.register([req], sigs)
              .then(function (data) {
                void 0
                self.keyData.u2f = { error: false, data: data }
                $scope.again = true
                self.requestOTP()
              })
              .catch(function (data) {
                void 0
                self.keyData.u2f = { error: true, code: data.metaData.code }
                $mdDialog.cancel(self.keyData)
              })
          })
      }

      self.requestOTP = function () {
        void 0
        $scope.isU2F = false
        $scope.isOTP = true
        $scope.form.otp = ''
        self.intervals.push(
          $interval(function () {
            document.newYubiKeyForm.otp.focus()
          }, 100)
        )
      }

      if (self.locals.isTwofactor) {
        u2fApi.isSupported()
          .then(function (supported) {
            void 0
            if (supported) {
              self.requestU2F()
            } else {
              self.requestOTP()
            }
          })
      } else {
        self.requestOTP()
      }
    }

    // dialog 3: name the key
    if (self.step === 3) {
      $scope.form = {
        name: '',
        submit: function () {
          var keyData = self.locals.keyData
          keyData.name = this.name
          $mdDialog.hide(keyData)
        },
      }
    }
  }
})()
