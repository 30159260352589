;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.management', [])
    .config(function ($stateProvider, moduleRegisterProvider) {
      $stateProvider
        .state('management', {
          url: '/management',
          abstract: true,
        })
        .state('management.customers', {
          url: '/customers/:customerId',
          params: {
            customerId: {
              squash: true,
              value: null,
            },
          },
          views: {
            '@': { templateUrl: 'src/management/views/layout.html' },
            'colLeft@management.customers': {
              templateUrl: 'src/management/views/customer-list.html',
              controller: function ($scope, customers) {
                $scope.customers = customers
              },
            },
          },
          resolve: {
            customers: function (CustomerService) {
              return CustomerService.getCustomers({ all: true }).$promise
            },
          },
        })
        .state('management.customers.details', {
          url: '/details',
          views: {
            'colRight@management.customers': {
              templateUrl: 'src/management/views/customer-details.html',
              controller: function ($scope, dataCustomer) {
                $scope.customer = dataCustomer
              },
            },
          },
          resolve: {
            dataCustomer: function ($transition$, CustomerService) {
              return CustomerService.getCustomer($transition$.params().customerId).$promise
            },
          },
        })
        .state('management.customers.new', {
          url: '/new',
          views: {
            'colRight@management.customers': {
              templateUrl: 'src/management/views/customer-form.html',
              controller: 'CustomerNewController',
            },
          },
          resolve: {
            dataSystems: function (CustomerService) {
              return CustomerService.getSystems().$promise
            },
            currencies: function (CurrencyService) {
              return CurrencyService.getAll().$promise
            },
          },
        })
        .state('management.customers.edit', {
          url: '/edit',
          views: {
            'colRight@management.customers': {
              templateUrl: 'src/management/views/customer-form.html',
              controller: 'CustomerEditController',
            },
          },
          resolve: {
            dataSystems: function (CustomerService) {
              return CustomerService.getSystems().$promise
            },
            dataCustomer: function ($transition$, CustomerService) {
              return CustomerService.getCustomer($transition$.params().customerId).$promise
            },
            currencies: function (CurrencyService) {
              return CurrencyService.getAll().$promise
            },
          },
        })
        .state('management.customers.options', {
          url: '/options',
          views: {
            'colRight@management.customers': {
              templateUrl: 'src/management/views/customer-options-form.html',
              controller: 'CustomerOptionsController',
            },
          },
          resolve: {
            dataCustomer: function ($transition$, CustomerService) {
              return CustomerService.getCustomer($transition$.params().customerId).$promise
            },
            dataOptions: function ($transition$, CustomerService) {
              return CustomerService.getOptions($transition$.params().customerId).$promise
            },
          },
        })
        .state('management.customers.members', {
          url: '/members/:memberId?{filter:json}',
          params: {
            memberId: {
              squash: true,
              value: null,
            },
            filter: { dynamic: true },
          },
          views: {
            '@': { templateUrl: 'src/management/views/layout.html' },
            'colLeft@management.customers.members': {
              templateUrl: 'src/management/views/member-list.html',
              controller: 'MemberListController',
            },
          },
          resolve: {
            dataMembers: function ($transition$, CustomerService) {
              return CustomerService.getMembers($transition$.params().customerId).$promise
            },
          },
        })
        .state('management.customers.members.details', {
          url: '/details',
          views: {
            'colRight@management.customers.members': {
              templateUrl: 'src/management/views/member-details.html',
              controller: function ($scope, dataMember) {
                $scope.member = dataMember
              },
            },
          },
          resolve: {
            dataMember: function ($transition$, CustomerService) {
              return CustomerService.getMember($transition$.params().customerId, $transition$.params().memberId)
                .$promise
            },
          },
        })
        .state('management.customers.members.new', {
          url: '/new',
          views: {
            'colRight@management.customers.members': {
              templateUrl: 'src/management/views/member-form.html',
              controller: 'MemberNewController',
            },
          },
        })
        .state('management.customers.members.edit', {
          url: '/edit',
          views: {
            'colRight@management.customers.members': {
              templateUrl: 'src/management/views/member-form.html',
              controller: 'MemberEditController',
            },
          },
          resolve: {
            dataMember: function ($transition$, CustomerService) {
              return CustomerService.getMember($transition$.params().customerId, $transition$.params().memberId)
                .$promise
            },
          },
        })
        .state('management.customers.members.edit.newuser', {
          url: '/create-user',
          views: {
            'colRight@management.customers.members': {
              templateUrl: 'src/management/views/user-form.html',
              controller: 'MemberUserNewController',
            },
          },
          resolve: {
            dataMember: function ($transition$, CustomerService) {
              return CustomerService.getMember($transition$.params().customerId, $transition$.params().memberId)
                .$promise
            },
            dataRoles: function (UserAdminService) {
              return UserAdminService.getRoles()
            },
            dataSystems: function ($transition$, CustomerService) {
              return CustomerService.getSystems($transition$.params().customerId).$promise
            },
          },
        })
        .state('management.customers.stores', {
          url: '/stores/:storeId',
          params: {
            storeId: {
              squash: true,
              value: null,
            },
          },
          views: {
            '@': { templateUrl: 'src/management/views/layout.html' },
            'colLeft@management.customers.stores': {
              templateUrl: 'src/management/views/store-list.html',
              controller: 'StoreListController',
            },
          },
          resolve: {
            dataCustomer: function ($transition$, CustomerService) {
              return CustomerService.getCustomer($transition$.params().customerId).$promise
            },
            dataStores: function ($transition$, CustomerService) {
              return CustomerService.getStores($transition$.params().customerId, {
                inactive: true,
                appendToStore: true,
              }).$promise
            },
          },
        })
        .state('management.customers.stores.details', {
          url: '/details',
          views: {
            'colRight@management.customers.stores': {
              templateUrl: 'src/management/views/store-details.html',
              controller: function ($scope, dataStore) {
                $scope.store = dataStore
              },
            },
          },
          resolve: {
            dataStore: function ($transition$, CustomerService) {
              return CustomerService.getStore($transition$.params().customerId, $transition$.params().storeId).$promise
            },
          },
        })
        .state('management.customers.stores.edit', {
          url: '/edit',
          views: {
            'colRight@management.customers.stores': {
              templateUrl: 'src/management/views/store-form.html',
              controller: 'StoreFormController',
            },
          },
          resolve: {
            dataStore: function ($transition$, CustomerService) {
              return CustomerService.getStore($transition$.params().customerId, $transition$.params().storeId).$promise
            },
          },
        })
        .state('management.customers.storegroups', {
          url: '/storegroups/:storegroupId',
          params: {
            storegroupId: {
              squash: true,
              value: null,
            },
          },
          views: {
            '@': { templateUrl: 'src/management/views/layout.html' },
            'colLeft@management.customers.storegroups': {
              templateUrl: 'src/management/views/storegroup-list.html',
              controller: 'StoregroupListController',
            },
          },
          resolve: {
            dataStoregroups: function ($transition$, CustomerService) {
              return CustomerService.getStoregroups($transition$.params().customerId).$promise
            },
          },
        })
        .state('management.customers.storegroups.details', {
          url: '/details',
          views: {
            'colRight@management.customers.storegroups': {
              templateUrl: 'src/management/views/storegroup-details.html',
              controller: function ($scope, $transition$, dataStoregroup) {
                $scope.customerId = $transition$.params().customerId
                $scope.storegroup = dataStoregroup
              },
            },
          },
          resolve: {
            dataStoregroup: function ($transition$, CustomerService) {
              return CustomerService.getStoregroup($transition$.params().customerId, $transition$.params().storegroupId)
                .$promise
            },
          },
        })
        .state('management.customers.storegroups.new', {
          url: '/new',
          views: {
            'colRight@management.customers.storegroups': {
              templateUrl: 'src/management/views/storegroup-form.html',
              controller: 'StoregroupFormController',
            },
          },
          resolve: {
            dataStoregroup: function () {
              return undefined
            },
            dataStores: function ($transition$, CustomerService) {
              return CustomerService.getStores($transition$.params().customerId, {
                inactive: true,
                appendToStore: true,
              }).$promise
            },
          },
        })
        .state('management.customers.storegroups.edit', {
          url: '/edit',
          views: {
            'colRight@management.customers.storegroups': {
              templateUrl: 'src/management/views/storegroup-form.html',
              controller: 'StoregroupFormController',
            },
          },
          resolve: {
            dataStoregroup: function ($transition$, CustomerService) {
              return CustomerService.getStoregroup($transition$.params().customerId, $transition$.params().storegroupId)
                .$promise
            },
            dataStores: function ($transition$, CustomerService) {
              return CustomerService.getStores($transition$.params().customerId, {
                inactive: true,
                appendToStore: true,
              }).$promise
            },
          },
        })

      $stateProvider
        .state('management.debitors', {
          url: '/debitors/:customerId',
          params: {
            customerId: {
              dynamic: true,
              squash: true,
              type: 'int',
              value: null,
            },
          },
          views: {
            '@': {
              templateUrl: 'src/management/views/layout.html',
            },
            'colLeft@management.debitors': {
              templateUrl: 'src/management/views/debitor-list.html',
              controller: 'DebitorListController',
            },
          },
        })
        .state('management.debitors.new', {
          url: '/new',
          views: {
            colRight: {
              templateUrl: 'src/management/views/debitor-form.html',
              controller: 'DebitorFormController',
            },
          },
        })
        .state('management.debitors.edit', {
          url: '/edit/:debitorId',
          params: {
            debitorId: {
              type: 'int',
            },
          },
          views: {
            colRight: {
              templateUrl: 'src/management/views/debitor-form.html',
              controller: 'DebitorFormController',
            },
          },
        })
        .state('management.debitors.export', {
          url: '/export',
          views: {
            '@': {
              templateUrl: 'src/management/views/layout-full.html',
            },
            'colMain@management.debitors.export': {
              templateUrl: 'src/management/views/debitor-export.html',
              controller: 'DebitorExportController',
            },
          },
        })

      moduleRegisterProvider.add({
        title: 'Verwaltung',
        materialIcon: 'account_balance',
        accessRoles: ['admin', 'accounting'],
        menu: [
          {
            name: 'Kunden',
            url: 'management.customers({ customerId: null })',
          },
          {
            name: 'Debitoren',
            url: 'management.debitors({ customerId: null })',
          },
        ],
      })
    })
    .factory('CustomerService', function (CONFIG, $resource, helperService) {
      var serviceInterface = this

      // define REST api calls
      var api = {
        customer: $resource(CONFIG.API_URL + '/customers/:customerId', { customerId: 0 }),
        option: $resource(CONFIG.API_URL + '/customers/:customerId/options', { customerId: 0 }),
        member: $resource(CONFIG.API_URL + '/customers/:customerId/members/:memberId', { customerId: 0, memberId: 0 }),
        memberuser: $resource(CONFIG.API_URL + '/customers/:customerId/members/:memberId/user', {
          customerId: 0,
          memberId: 0,
        }),
        store: $resource(CONFIG.API_URL + '/customers/:customerId/stores/:storeId', { customerId: 0 }),
        storesync: $resource(CONFIG.API_URL + '/customers/:customerId/stores/:storeId/sync', {
          customerId: 0,
          storeId: 0,
        }),
        deliveryarea: $resource(CONFIG.API_URL + '/customers/:customerId/stores/:storeId/deliveryarea', {
          customerId: 0,
          storeId: 0,
        }),
        deliveryareas: $resource(CONFIG.API_URL + '/customers/:customerId/stores/:storeId/deliveryareas', {
          customerId: 0,
          storeId: 0,
        }),
        storegroup: $resource(CONFIG.API_URL + '/customers/:customerId/storegroups/:storegroupId', {
          customerId: 0,
          storegroupId: 0,
        }),
        system: $resource(CONFIG.API_URL + '/systems'),
        dpos: $resource(CONFIG.API_URL + '/customers/:customerId/dpos', { customerId: 0 }),
        dposStore: $resource(
          CONFIG.API_URL + '/customers/:customerId/stores/:storeId/dpos',
          { customerId: 0, storeId: 0 },
          {
            get: { method: 'GET', hideGlobalSpinner: true },
          }
        ),
      }

      // define public functions
      serviceInterface.getCustomers = function (params) {
        return api.customer.query(params)
      }
      serviceInterface.getCustomer = function (customerId) {
        return api.customer.get({ customerId: customerId })
      }
      serviceInterface.createCustomer = function (customer) {
        return api.customer.add(customer)
      }
      serviceInterface.editCustomer = function (customerId, customer) {
        return api.customer.save({ customerId: customerId }, customer)
      }
      serviceInterface.deleteCustomer = function (customerId) {
        return api.customer.delete({ customerId: customerId })
      }

      serviceInterface.getOptions = function (customerId) {
        return api.option.get({ customerId: customerId })
      }
      serviceInterface.setOptions = function (type, customerId, options) {
        options.options_type = type
        return api.option.save({ customerId: customerId }, options)
      }
      serviceInterface.testOptions = function (customerId) {
        return api.option.get({ customerId: customerId, test: true })
      }

      serviceInterface.getMembers = function (customerId) {
        return api.member.query({ customerId: customerId })
      }
      serviceInterface.getMember = function (customerId, memberId) {
        return api.member.get({ customerId: customerId, memberId: memberId })
      }
      serviceInterface.createMember = function (customerId, member) {
        return api.member.add({ customerId: customerId }, member)
      }
      serviceInterface.editMember = function (customerId, memberId, member) {
        return api.member.save({ customerId: customerId, memberId: memberId }, member)
      }
      serviceInterface.deleteMember = function (customerId, memberId) {
        return api.member.delete({ customerId: customerId, memberId: memberId })
      }
      serviceInterface.createMemberUser = function (customerId, memberId, user) {
        return api.memberuser.add({ customerId: customerId, memberId: memberId }, user)
      }

      serviceInterface.getStores = function (customerId, additionalParams) {
        return api.store.query(Object.assign({ customerId: customerId }, additionalParams))
      }
      serviceInterface.getStoresWithGroups = function (customerId, additionalParams) {
        return api.store.get(Object.assign({ customerId: customerId, storegroup: true }, additionalParams))
      }
      serviceInterface.getStore = function (customerId, storeId) {
        return api.store.get({ customerId: customerId, storeId: storeId })
      }
      serviceInterface.editStore = function (customerId, store) {
        return api.store.update({ customerId: customerId, storeId: store.storeId }, store)
      }
      serviceInterface.syncStores = function (customerId) {
        return api.storesync.get({ customerId: customerId })
      }
      serviceInterface.syncStore = function (customerId, storeId) {
        return api.storesync.get({ customerId: customerId, storeId: storeId })
      }
      serviceInterface.uploadDeliveryArea = function (customerId, storeId, file) {
        var params = {
          customerId: customerId,
          storeId: storeId,
          FILE_UPLOAD: true,
        }
        return api.deliveryarea.create(params, { file: file }).$promise
      }
      serviceInterface.getDeliveryareas = function (customerId, storeId) {
        var params = {
          customerId: customerId,
          storeId: storeId,
        }
        return api.deliveryareas.query(params).$promise
      }

      serviceInterface.getStoregroups = function (customerId) {
        return api.storegroup.query({ customerId: customerId })
      }
      serviceInterface.getStoregroup = function (customerId, storegroupId) {
        return api.storegroup.get({ customerId: customerId, storegroupId: storegroupId })
      }
      serviceInterface.createStoregroup = function (customerId, storegroup) {
        return api.storegroup.add({ customerId: customerId }, storegroup)
      }
      serviceInterface.editStoregroup = function (customerId, storegroupId, storegroup) {
        return api.storegroup.save({ customerId: customerId, storegroupId: storegroupId }, storegroup)
      }
      serviceInterface.deleteStoregroup = function (customerId, storegroupId) {
        return api.storegroup.delete({ customerId: customerId, storegroupId: storegroupId })
      }

      serviceInterface.getSystems = function (customerId) {
        return api.system.query({ customerId: customerId })
      }

      serviceInterface.getDPos = function (customerId) {
        return api.dpos.query({ customerId: customerId })
      }
      serviceInterface.getDPosStore = function (customerId, storeId) {
        return api.dposStore.get({ customerId: customerId, storeId: storeId })
      }

      return serviceInterface
    })
    .factory('CurrencyService', function (CONFIG, $resource) {
      var api = {
        currency: $resource(CONFIG.API_URL + '/currency'),
      }

      var service = {
        getAll: currency,
      }
      return service

      function currency() {
        return api.currency.query()
      }
    })
})()
