;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.filters', [])
    .constant('PHONENUMBER_COUNTRY', 'DE')

    .filter('intersection', function () {
      // array intersection
      return function (arrA, arrB, objKey) {
        if (!Array.isArray(arrA)) {
          return
        }
        return arrA.filter(function (element) {
          if (objKey !== undefined) {
            return arrB.includes(element[objKey])
          }
          return arrB.includes(element)
        })
      }
    })
    .filter('complement', function () {
      // array complement
      return function (arrA, arrB, objKey) {
        if (!Array.isArray(arrA)) {
          return
        }
        return arrA.filter(function (element) {
          if (objKey !== undefined) {
            return !arrB.includes(element[objKey])
          }
          return !arrB.includes(element)
        })
      }
    })

    .filter('join', function () {
      // join array into string
      return function (array, separator) {
        if (!Array.isArray(array)) {
          return
        }
        return array.join(separator)
      }
    })
    .filter('split', function () {
      // split string into array
      return function (string, separator, limit) {
        if (typeof string != 'string') {
          return
        }
        return string.split(separator, limit)
      }
    })

    // aggregation filters
    .filter('avg', function ($filter) {
      return function (array, prop) {
        if (!Array.isArray(array)) {
          return
        }
        return $filter('sum')(array, prop) / array.length
      }
    })
    .filter('sum', function (helperService) {
      return function (array, prop) {
        if (angular.isObject(array)) {
          array = Object.values(array)
        }
        if (!Array.isArray(array)) {
          return
        }
        var sum = 0
        for (var i = 0, len = array.length; i < len; i++) {
          var value = array[i]
          if (prop) {
            value = helperService.fetchFromObject(value, prop)
          }
          sum += typeof value == 'number' ? value : 0
        }
        return sum
      }
    })
    .filter('max', function () {
      return function (array, prop) {
        if (!Array.isArray(array) || !array.length) {
          return
        }
        var max = array[0]
        for (var i = 1, len = array.length; i < len; i++) {
          if ((prop && array[i][prop] > max[prop]) || (!prop && array[i] > max)) {
            max = array[i]
          }
        }
        return max
      }
    })
    .filter('min', function () {
      return function (array, prop) {
        if (!Array.isArray(array) || !array.length) {
          return
        }
        var min = array[0]
        for (var i = 1, len = array.length; i < len; i++) {
          if ((prop && array[i][prop] < min[prop]) || (!prop && array[i] < min)) {
            min = array[i]
          }
        }
        return min
      }
    })

    .filter('basename', function () {
      return function (path) {
        if (!angular.isString(path)) {
          return path
        }
        return path.split(/[\\/]/).pop()
      }
    })
    .filter('bytes2Size', function () {
      return function (bytes, decimals) {
        if (bytes === 0) {
          return '0 Bytes'
        }
        var k = 1000
        var dm = decimals < 0 ? 0 : decimals || 0
        var sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'RB', 'QB']
        var i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
      }
    })
    .filter('dateDiff', function (helperService) {
      return function (dates, include) {
        if (!Array.isArray(dates) || dates.length !== 2) {
          return
        }
        return helperService.dateDiffDays(dates[0], dates[1]) + (include ? 1 : 0)
      }
    })
    .filter('dateRange', function ($filter) {
      return function (range) {
        if (!Array.isArray(range) || range.length !== 2) {
          return
        }

        var from = new Date(range[0])
        var fromFormat = 'dd.MM.yyyy'
        var to = new Date(range[1])
        var toFormat = 'dd.MM.yyyy'

        if (isNaN(from) || isNaN(to)) {
          return
        }

        from.setHours(0, 0, 0, 0)
        to.setHours(0, 0, 0, 0)

        if (from.getTime() === to.getTime()) {
          return $filter('date')(from, fromFormat)
        }

        if (from.getYear() === to.getYear()) {
          fromFormat = 'dd.MM.'
        }

        return $filter('date')(from, fromFormat) + '–' + $filter('date')(to, toFormat)
      }
    })
    .filter('domain', function () {
      // get the domain from url
      return function (url) {
        if (typeof url != 'string') {
          return url
        }
        var matches = url.match(/([^/]+)/g)
        if (matches.length >= 2) {
          return matches[1]
        }
        return url
      }
    })
    .filter('googlemaps', function () {
      var placeUrlPrefix = 'https://www.google.de/maps/place/'

      return function (address) {
        if (!address) {
          return
        }
        var place = address.streetName + ' ' + address.streetNumber + ', ' + address.postalCode + ' ' + address.city
        return placeUrlPrefix + encodeURIComponent(place)
      }
    })
    .filter('momentjs', function () {
      return function (date) {
        if (!moment(date).isValid()) {
          return date
        }
        return moment(date)
      }
    })
    .filter('nl2br', function () {
      return function (text) {
        if (!angular.isString(text)) {
          return text
        }
        return text.replace(/\n/g, '<br>')
      }
    })
    .filter('orderByNat', function (orderByFilter) {
      if (typeof String.naturalCompare !== 'function') {
        return orderByFilter
      }
      return function (collection, expression, reverse, comparator) {
        return orderByFilter(collection, expression, reverse, (a, b) => String.naturalCompare(a.value, b.value))
      }
    })
    .filter('parseInt', function () {
      return function (i) {
        return parseInt(i)
      }
    })
    .filter('percent', function (numberFilter) {
      return function (number, fractionSize, options) {
        if (!Number.isFinite(number)) {
          number = 0
        }
        if (fractionSize == null) {
          fractionSize = 0
        }
        return numberFilter(number * 100, fractionSize, options) + ' %'
      }
    })
    .filter('powerset', function () {
      return function (array) {
        if (!Array.isArray(array)) {
          return
        }

        var powerset = []

        var length = array.length
        var size = Math.pow(2, length)
        var mask, bit, subset

        for (mask = 0; mask < size; mask++) {
          subset = []
          if (mask) {
            for (bit = 0; bit < length; bit++) {
              if (mask & (1 << bit)) {
                subset.push(array[bit])
              }
            }
          }
          powerset.push(subset)
        }

        return powerset
      }
    })
    .filter('randomInt', function () {
      return function (array) {
        var min = Math.ceil(array[0])
        var max = Math.floor(array[1])

        if (window.crypto) {
          var value = window.crypto.getRandomValues(new Uint8Array(1))[0]
          return Math.round((value * (max - min)) / 255 + min)
        }

        return Math.floor(Math.random() * (max - min + 1) + min)
      }
    })
    .filter('range', function () {
      // create a range array
      return function (array, start, end, step) {
        if (!Array.isArray(array)) {
          return
        }
        if (undefined === end) {
          end = start
          start = 1
        }
        if (undefined === step) {
          step = 1
        }
        for (var i = start; i <= end; i += step) {
          array.push(i)
        }
        return array
      }
    })
    .filter('state', function () {
      var LIST = {
        BW: 'Baden-Württemberg',
        BY: 'Bayern',
        BE: 'Berlin',
        BB: 'Brandenburg',
        HB: 'Bremen',
        HH: 'Hamburg',
        HE: 'Hessen',
        MV: 'Mecklenburg-Vorpommern',
        NI: 'Niedersachsen',
        NW: 'Nordrhein-Westfalen',
        RP: 'Rheinland-Pfalz',
        SL: 'Saarland',
        SN: 'Sachsen',
        ST: 'Sachsen-Anhalt',
        SH: 'Schleswig-Holstein',
        TH: 'Thüringen',
      }
      return function (abbr) {
        return LIST[abbr] || abbr
      }
    })
    .filter('stripHtml', function () {
      return function (string) {
        if (string) {
          return angular.element('<div>').html(string).text()
        }
        return string
      }
    })
    .filter('suffix', function () {
      return function (expr, suffix) {
        if (expr && suffix) {
          return expr + suffix
        }
        return expr
      }
    })
    .filter('tel', function (PHONENUMBER_COUNTRY) {
      return function (number, options) {
        if (!number) {
          return
        }
        if (!number.match(/^[0+]/)) {
          return number
        }
        options = Object.assign({ nospace: false }, options)
        try {
          var phoneNumber = libphonenumber.parsePhoneNumber(number, PHONENUMBER_COUNTRY)
          if (phoneNumber.isValid()) {
            var formattedPhoneNumber
            if (phoneNumber.country === PHONENUMBER_COUNTRY) {
              formattedPhoneNumber = phoneNumber.formatNational()
            } else {
              formattedPhoneNumber = phoneNumber.formatInternational()
            }
            if (options.nospace) {
              formattedPhoneNumber = formattedPhoneNumber.replace(/\s+/g, '')
            }
            return formattedPhoneNumber.replace(/\s/g, '\xa0')
          }
        } catch (error) {}
        return number // fallback
      }
    })
    .filter('teluri', function (PHONENUMBER_COUNTRY) {
      return function (number) {
        try {
          var phoneNumber = libphonenumber.parsePhoneNumber(number, PHONENUMBER_COUNTRY)
          if (phoneNumber.isValid()) {
            return phoneNumber.getURI()
          }
        } catch (error) {}
        return 'tel:+'
      }
    })
    .filter('timeNotNull', function ($filter) {
      return function (date) {
        var HH = $filter('date')(date, 'HH', 'UTC')
        var mm = $filter('date')(date, 'mm', 'UTC')
        var ss = $filter('date')(date, 'ss', 'UTC')
        var string
        if (HH > 0) {
          string = HH + ':' + mm + ':' + ss
        } else if (mm > 0) {
          string = mm + ':' + ss
        } else if (ss > 0) {
          string = ss
        }
        return string
      }
    })
    .filter('timer', function () {
      // countdown timer for transmissions
      return function (transmission, diff2be) {
        if (!transmission.order || transmission.remainingTime) {
          return
        }
        var goal = transmission.order.deliveryTime || transmission.shouldBeDelivered
        if (goal) {
          goal = new Date(goal)
          if (goal.getTime() > 0) {
            transmission.remainingTime = {
              goal: goal,
              diff: null,
            }
            var now = new Date(Date.now() - diff2be)
            var diff = new Date(goal - now)
            if (diff.getTime() > 0) {
              transmission.remainingTime.diff = diff
            } else {
              transmission.remainingTime.diff = new Date(0)
            }
          }
        }
      }
    })
    .filter('trust', function ($sce, $sceDelegate) {
      var defaultType = $sce.HTML
      return function (value, type) {
        return $sceDelegate.trustAs(type || defaultType, value)
      }
    })
    .filter('unemoji', function () {
      return function (string) {
        if (typeof string !== 'string') {
          return string
        }
        return string.replace(/[^\x00-\xFF]/g, '').trim()
      }
    })
    .filter('week', function () {
      return function (date) {
        if (!date) {
          return
        }
        return moment(date).week()
      }
    })
    .filter('weekday', function () {
      return function (number) {
        return moment().weekday(number).format('dddd')
      }
    })
})()
