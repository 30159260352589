(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager', [
      'ngAnimate',
      'ngCookies',
      'ngMaterial',
      'ngMessages',
      'ngOnload',
      'ngResource',
      'ng-showdown',
      'ng-sortable',
      'ui-notification',
      'ui.router',
      'googlechart',
      'angular-easy-image-preloader',
      'ottomatikStoreManager.decorators',
      'ottomatikStoreManager.filters',
      'ottomatikStoreManager.services',
      'ottomatikStoreManager.index',
      'ottomatikStoreManager.reporting',
      'ottomatikStoreManager.technicalservice',
      'ottomatikStoreManager.user',
      'ottomatikStoreManager.administration',
      'ottomatikStoreManager.management',
      'ottomatikStoreManager.servicecenter',
      'ottomatikStoreManager.transmissions',
      'ottomatikStoreManager.orders',
      'ottomatikStoreManager.statistics',
      'ottomatikStoreManager.ranking',
      'ottomatikStoreManager.crm',
      'ottomatikStoreManager.hrm',
      'ottomatikStoreManager.catalog',
      'ottomatikStoreManager.dpos',
      'ottomatikStoreManager.files',
      'ottomatikStoreManager.help',
      'ottomatikStoreManager.tutorials',
      'ottomatikStoreManager.storesettings',
    ])
    .config(function (ENV, $compileProvider, $cookiesProvider, $httpProvider, $resourceProvider, NotificationProvider, $mdDateLocaleProvider, $mdIconProvider, agcLibraryLoaderProvider, agcGstaticLoaderProvider) {
      var helperService

      // boost AngularJS performance
      $compileProvider.debugInfoEnabled(ENV.DEVELOPMENT) // disable debug data on production
      $compileProvider.commentDirectivesEnabled(false) // disable comment directives
      $compileProvider.cssClassDirectivesEnabled(false) // disable css class directives

      // cookie defaults
      $cookiesProvider.defaults.path = '/;samesite=Lax'
      $cookiesProvider.defaults.secure = ENV.HTTPS

      // configure HTTP provider
      $httpProvider.useLegacyPromiseExtensions(false) // return promises without the deprecated legacy success and error methods
      $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest' // important for the backend to recognize AJAX requests
      $httpProvider.defaults.headers.common['Build-Number'] = ENV.BUILD_NUMBER
      if (window.name) {
        $httpProvider.defaults.headers.common['Window-Name'] = window.name
      }
      $httpProvider.defaults.paramSerializer = '$httpParamSerializerJQLike' // alternative params serializer that follows jQuery's param() method logic
      $httpProvider.interceptors.push('httpInterceptor') // set custom request/response interceptor

      // add default resource actions
      $resourceProvider.defaults.actions.create = { method: 'POST' }
      $resourceProvider.defaults.actions.add = { method: 'PUT' }
      $resourceProvider.defaults.actions.update = { method: 'PUT' }
      $resourceProvider.defaults.actions.export = {
        params: { export: true },
        responseType: 'arraybuffer',
        transformResponse: function (data, headers, status) {
          if (!helperService) {
            helperService = angular.element(document.body).injector().get('helperService')
          }
          return helperService.data2download(data, headers, status)
        },
      }

      // configure notification provider
      NotificationProvider.setOptions({
        delay: 15000,
        startTop: 24,
        startRight: 24,
        verticalSpacing: 24,
        horizontalSpacing: 24,
        // positionX: 'right',
        positionY: 'bottom',
        maxCount: 10,
      })

      // configure mdDateLocale service
      $mdDateLocaleProvider.months = moment.localeData('de')._months
      $mdDateLocaleProvider.shortMonths = moment.localeData('de')._monthsShort
      $mdDateLocaleProvider.days = moment.localeData('de')._weekdays
      $mdDateLocaleProvider.shortDays = moment.localeData('de')._weekdaysShort
      $mdDateLocaleProvider.firstDayOfWeek = moment.localeData('de')._week.dow
      $mdDateLocaleProvider.parseDate = function (dateString) {
        var m = moment(dateString, 'L', true)
        return m.isValid() ? m.toDate() : new Date(NaN)
      }
      $mdDateLocaleProvider.formatDate = function (date) {
        var m = moment(date)
        return date && m.isValid() ? m.format('L') : ''
      }
      $mdDateLocaleProvider.monthHeaderFormatter = function (date) {
        return $mdDateLocaleProvider.shortMonths[date.getMonth()] + ' ' + date.getFullYear()
      }
      $mdDateLocaleProvider.monthFormatter = function (date) {
        return $mdDateLocaleProvider.months[date.getMonth()]
      }
      $mdDateLocaleProvider.weekNumberFormatter = function (weekNumber) {
        return 'Woche ' + weekNumber
      }
      $mdDateLocaleProvider.longDateFormatter = function (date) {
        var m = moment(date)
        return date && m.isValid() ? m.format('LL') : ''
      }
      $mdDateLocaleProvider.msgCalendar = 'Kalender'
      $mdDateLocaleProvider.msgOpenCalendar = 'Kalender öffnen'
      $mdDateLocaleProvider.firstRenderableDate = new Date(2015, 0, 1)

      // fontset alias
      $mdIconProvider.fontSet('outlined', 'material-icons-outlined')

      // define svg icons
      $mdIconProvider.icon('cancel', 'img/cancel.svg')
      $mdIconProvider.icon('check', 'img/check_circle.svg')
      $mdIconProvider.icon('fa:eraser', 'img/fontawesome/eraser-solid.svg')
      $mdIconProvider.icon('fa:excel', 'img/fontawesome/file-excel-solid.svg')
      $mdIconProvider.icon('help', 'img/help-icon.svg')
      $mdIconProvider.icon('loader', 'img/loader-kit.svg')
      $mdIconProvider.icon('report', 'img/report-call.svg')
      $mdIconProvider.icon('customersupport', 'img/customersupport.svg')
      $mdIconProvider.icon('slack', 'img/Slack_Mark.svg')
      $mdIconProvider.icon('slack_black', 'img/Slack_Mark_Monochrome_Black.svg')

      // google chart loader
      agcLibraryLoaderProvider.setLoader('gstatic')
      agcGstaticLoaderProvider.setOption('language', 'de')
    })
    .run(function ($q, $rootScope, $transitions, $window, Notification, moduleRegister, preloader, spinnerService, UserService) {
      $rootScope.globals = {
        currentUser: null,
        sessionChecked: false,
      }

      // preload images
      var imageLocations = [
        'img/cancel.svg',
        'img/check_circle.svg',
        'img/customersupport.svg',
        'img/help-icon.svg',
        'img/loader-kit.svg',
        'img/report-call.svg',
        'img/spinner-200px.gif',
        'img/spinner-200px.svg',
        'img/spinner-32px.gif',
        'img/spinner-32px.svg',
        'img/store-icon.png',
        'img/icons/commentary-icon.png',
        'img/icons/commentary-icon@2x.png',
        'img/icons/icon-adresszusatz.png',
        'img/icons/icon-adresszusatz@2x.png',
        'img/icons/icon-cancellation.png',
        'img/icons/icon-cancellation@2x.png',
        'img/icons/icon-ec.png',
        'img/icons/icon-ec@2x.png',
        'img/icons/icon-extras.png',
        'img/icons/icon-extras@2x.png',
        'img/icons/icon-go-blue.png',
        'img/icons/icon-go-red.png',
        'img/icons/icon-onlinepayment.png',
        'img/icons/icon-onlinepayment@2x.png',
        'img/icons/icon-preorder.png',
        'img/icons/icon-preorder@2x.png',
        'img/icons/icon-takeaway.png',
        'img/icons/icon-takeaway@2x.png',
        'img/icons/icon-termin.png',
        'img/icons/icon-termin@2x.png',
        'img/icons/icon-testorder.png',
        'img/icons/icon-testorder@2x.png',
        'img/icons/payment-sprite.png',
        'img/icons/walking-men-sprite.png',
        'img/icons/walking-men-sprite@2x.png',
      ]
      preloader.preloadImages(imageLocations)

      $transitions.onBefore({}, function (trans) {
        $rootScope.isSidenavReportingOpen = false
        $rootScope.isSidenavOpenemmOpen = false
        $rootScope.isSidenavCRMOpen = false
        $rootScope.isSidenavOrderDetailsOpen = false

        var promise
        if (!$rootScope.globals.sessionChecked) {
          promise = UserService.getUser()
          promise.finally(function () {
            $rootScope.globals.sessionChecked = true
          })
        } else {
          promise = $q(function (resolve, reject) {
            resolve($rootScope.globals.currentUser)
          })
        }

        return promise.then(function (user) {
          // handle redirect
          var redirect = UserService.getRedirect() || {}
          if (redirect.state) {
            return trans.router.stateService.target(redirect.state, redirect.params || {})
          }

          // check user & route
          var userIsLoggedIn = Boolean(user)
          var routeIsPublic = Boolean(trans.to().data ? trans.to().data.public : false)
          if (userIsLoggedIn === routeIsPublic) {
            var goto = 'index'
            if (!userIsLoggedIn) {
              UserService.setRedirectAfterLogin(trans.to(), trans.params())
              goto = 'user.login'
            }
            return trans.router.stateService.target(goto)
          }

          if (!userIsLoggedIn) {
            return
          }

          // check redirect after login
          redirect = UserService.getRedirectAfterLogin() || {}
          if (redirect.state && redirect.state !== trans.to().name) {
            return trans.router.stateService.target(redirect.state, redirect.params || {})
          }
          if (redirect.url) {
            Notification.info({
              title: 'Weiterleitung',
              message: 'Sie werden jetzt zur gewünschten Seite weitergeleitet...',
              delay: null,
              closeOnClick: false,
            })
            spinnerService.show('spinnerRedirect')
            $window.location.href = redirect.url
            return
          }

          // role based access control
          if (!moduleRegister.userCanAccessState(trans.to().name)) {
            return trans.router.stateService.target('error')
          }
        })
      })
    })
    .controller('AppController', function (CONFIG, CUSTOMER_THEME, THEME_OPTIONS, $$mdMeta, $filter, $mdDialog, $mdSidenav, $mdTheming, $log, $resource, $rootScope, $scope, $state, $window, moduleRegister, ClipboardService, CRMService, LocalStorageService, UserAgentService, UserService) {
      var app = this

      $rootScope.MEDIA_BASE_URL = CONFIG.MEDIA_BASE_URL

      $rootScope.$watch('globals.currentUser', function (currentUser) {
        if (currentUser) {
          app.navigation = moduleRegister.getMenuItems(UserService.getRoles())
          app.singleSite = app.navigation.length === 1 && app.navigation[0].sub.length === 0
          app.minimalUi = UserService.hasRole('minimal-ui')
          app.userHasRoleTransmissions = UserService.hasRole('transmissions')
          app.allowThemeChange = true
          if (currentUser.customerId in CUSTOMER_THEME) {
            app.allowThemeChange = false
            app.changeTheme(CUSTOMER_THEME[currentUser.customerId])
          }
          if (app.singleSite) {
            $state.go(app.navigation[0].url)
          }
        }
      })

      app.themes = {
        default: {
          name: 'Hell',
          desc: 'Helles Design',
        },
        dark: {
          name: 'Dunkel',
          desc: 'Dunkles Design',
        },
        device: {
          name: 'Gerätedesign',
          desc: 'Gerätedesign verwenden',
          show: UserAgentService.supportsDeviceColorScheme(),
        },
      }
      app.moreThemes = {
        callapizza: {
          name: 'Call a Pizza',
          desc: 'Call a Pizza',
        },
      }
      app.changeTheme = function (theme) {
        if (app.themeActive === theme) {
          return
        }
        app.themeActive = theme
        if (theme === 'device') {
          theme = UserAgentService.getDeviceColorScheme({ lightAs: 'default' })
        }
        app.theme = theme
        $rootScope.appTheme = theme
        if ($window.name !== 'takeover') {
          LocalStorageService.set('THEME', app.themeActive)
        }

        var themePalette = 'primary'
        var paletteHue = 'default'
        var palette = $mdTheming.THEMES[theme].colors[themePalette].name
        var hue = $mdTheming.THEMES[theme].colors[themePalette].hues[paletteHue]
        var color = $mdTheming.PALETTES[palette][hue].hex

        $mdTheming.setBrowserColor({ palette: palette, hue: hue })
        $$mdMeta.setMeta('msapplication-TileColor', color)
        document.querySelector('head > link[rel="mask-icon"]').setAttribute('color', color)

        var themeOptions = THEME_OPTIONS[app.theme] || THEME_OPTIONS.default
        $rootScope.appTitle = themeOptions.title
        $rootScope.appAbbr = themeOptions.abbr
        app.showLogo = Boolean(themeOptions.showLogo)
      }

      var defaultTheme = 'default'
      angular.forEach(THEME_OPTIONS, function (options, theme) {
        if (options.url && options.url === window.location.host) {
          defaultTheme = theme
        }
      })
      app.changeTheme(LocalStorageService.get('THEME', defaultTheme))

      UserAgentService.watchDeviceColorScheme({ lightAs: 'default' }, function (theme) {
        if (app.themeActive === 'device') {
          $rootScope.$apply(
            function () {
              app.theme = theme
              $rootScope.appTheme = theme
            }
          )
        }
      })

      app.toggleMenu = function () {
        $mdSidenav('left').toggle()
      }

      function trimStateParams(state) {
        if (typeof state == 'string' || state instanceof String) {
          return state.replace(/\(.*\)$/, '')
        }
        return state
      }

      app.navIsActive = function (item) {
        if (app.collapsenavigation) {
          return app.navIsOpen(item)
        }

        if (item.activeStateCheck) {
          var activeStateCheck = [].concat(item.activeStateCheck)
          for (var i = 0, len = activeStateCheck.length; i < len; i++) {
            if ($state.includes(trimStateParams(activeStateCheck[i]))) {
              return true
            }
          }
        }

        return $state.includes(trimStateParams(item.url)) && (!item.sub || item.sub.length <= 1)
      }

      app.navIsOpen = function (item) {
        if (item.url) {
          item = trimStateParams(item.url)
        }
        return $state.includes(item.split('.')[0])
      }

      var shutdown = $resource('http://localhost:5454/')
      app.canShutdown = function () {
        return UserAgentService.isOTTOPI()
      }
      app.shutdown = function () {
        shutdown.get()
      }

      $scope.copy = function (event, text) {
        if (!ClipboardService.copy(text)) {
          return false
        }
        var element
        for (var i = 0, len = event.path.length; i < len; i++) {
          if (event.path[i].attributes['ng-click']) {
            element = event.path[i]
            break
          }
        }
        if (element) {
          var parentRect = element.getBoundingClientRect()
          var tooltip = document.createElement('div')
          tooltip.classList.add('short-tooltip', 'small')
          tooltip.style.backgroundColor = 'rgb(0 0 0 / 75%)'
          tooltip.style.color = 'white'
          tooltip.style.borderRadius = '4px'
          tooltip.style.padding = '4px 8px'
          tooltip.style.position = 'absolute'
          tooltip.style.left = Math.round(parentRect.left + parentRect.width / 2) + 'px'
          tooltip.style.top = Math.round(parentRect.top - 13) + 'px'
          tooltip.style.zIndex = 99
          tooltip.innerText = 'Kopiert!'
          document.body.appendChild(tooltip)
          var tooltipRect = tooltip.getBoundingClientRect()
          tooltip.style.left = Math.round(tooltipRect.left - tooltipRect.width / 2) + 'px'
          window.setTimeout(function () {
            tooltip.parentNode.removeChild(tooltip)
          }, 1000)
        }
        return true
      }

      $scope.copyPhone = function (event, phone) {
        phone = $filter('tel')(phone, { nospace: true })
        return $scope.copy(event, phone)
      }

      $scope.closeSidenav = function (id) {
        $mdSidenav(id).close()
      }

      $scope.openCustomerDetails = function (customer, phonenumber) {
        CRMService.openCustomerDetails(customer, phonenumber)
      }

      var Order = $resource(CONFIG.API_URL + '/orders/id/:orderId')
      $rootScope.updateOrderInfo = function (order, name, value) {
        var data = {}
        data[name] = value
        return Order.update({ orderId: order.orderId }, data).$promise
          .then(function () {
            order.infos[name] = value
          })
      }

      app.collapsenavigation = LocalStorageService.get('collapsenavigation')
      $scope.collapseNavigation = function () {
        app.collapsenavigation = !app.collapsenavigation
        LocalStorageService.set('collapsenavigation', app.collapsenavigation)
      }

      $scope.qrcode = function (event, title, data, options) {
        if (!angular.isObject(data)) {
          data = {
            content: data,
          }
        }
        if (data.content == null) {
          return
        }

        var alert = $mdDialog.alert()
          .title(title || 'QR-Code')
          .targetEvent(event)
          .ok('Schließen')

        alert._options.fullscreen = true
        alert._options.onComplete = function (scope, element) {
          var container = element[0].querySelector('.md-dialog-content-body')
          container.style.textAlign = 'center'

          var paragraph = container.querySelector('p')

          QRCode.toCanvas(data.content.toString(), options, function (error, canvas) {
            if (data.desc) {
              paragraph.classList.add('muted')
              paragraph.innerText = data.desc
            }

            if (error) {
              $log.error(error)
              var p = document.createElement('p')
              p.classList.add('warning')
              p.innerText = 'Fehler beim Erstellen des QR-Codes!'
              container.appendChild(p)
              return
            }

            container.appendChild(canvas)
          })
        }

        $mdDialog.show(alert)
      }

      $scope.moment = moment
    })
})()
