;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.crm').controller('CRMCampaignController', CRMCampaignController)

  function CRMCampaignController($mdDialog, $q, CRMCampaignService, CustomerService) {
    var $ctrl = this

    CustomerService.getCustomers().$promise.then((customers) => {
      $ctrl.customers = customers
    })

    $ctrl.customerSelect = (customerId, customer) => {
      $ctrl.customerId = customerId
      $ctrl.customer = customer
      $q.all([loadSettingsList(customer), loadTypeList(customer), loadStores(customer)]).then(() =>
        loadCampaigns(customer)
      )
    }

    $ctrl.manageSettingsList = (event) => {
      $mdDialog.show({
        templateUrl: 'src/crm/views/dialog.campaign-settings.list.html',
        targetEvent: event,
        clickOutsideToClose: true,
        controller: CampaignSettingsListController,
        locals: {
          customer: $ctrl.customer,
          settingsList: $ctrl.settingsList,
        },
        bindToController: true,
        controllerAs: 'listCtrl',
        fullscreen: true,
      })
    }

    $ctrl.manageTypeList = (event) => {
      $mdDialog.show({
        templateUrl: 'src/crm/views/dialog.campaign-type.list.html',
        targetEvent: event,
        clickOutsideToClose: true,
        controller: CampaignTypeListController,
        locals: {
          customer: $ctrl.customer,
          typeList: $ctrl.typeList,
        },
        bindToController: true,
        controllerAs: 'listCtrl',
        fullscreen: true,
      })
    }

    $ctrl.newCampaign = (event) => openCampaignFormDialog(event, {}, CRMCampaignService.createCampaign)
    $ctrl.editCampaign = (event, campaign) => openCampaignFormDialog(event, campaign, CRMCampaignService.updateCampaign)

    function loadSettingsList(customer) {
      var params = {
        customer: customer.customerId,
      }

      return CRMCampaignService.getSettingsList(params).then((response) => {
        $ctrl.settingsList = response
      })
    }

    function loadTypeList(customer) {
      var params = {
        customer: customer.customerId,
      }

      return CRMCampaignService.getTypeList(params).then((response) => {
        $ctrl.typeList = response
      })
    }

    function loadStores(customer) {
      return CustomerService.getStoresWithGroups(customer.customerId, {
        inactive: true,
        appendToStore: true,
      }).$promise.then((response) => {
        $ctrl.stores = response.stores
        $ctrl.storegroups = response.groups
      })
    }

    function loadCampaigns(customer) {
      var params = {
        customer: customer.customerId,
      }

      return CRMCampaignService.getCampaigns(params).then((response) => {
        $ctrl.campaigns = response
        partitionCampaigns()
      })
    }

    function openCampaignFormDialog(event, campaign, saveHandler) {
      var isNew = angular.equals(campaign, {})
      $mdDialog
        .show({
          templateUrl: 'src/crm/views/dialog.campaign.form.html',
          targetEvent: event,
          controller: CampaignFormController,
          locals: {
            customer: $ctrl.customer,
            stores: $ctrl.stores,
            storegroups: $ctrl.storegroups,
            settingsList: $ctrl.settingsList,
            typeList: $ctrl.typeList,
            saveHandler: saveHandler,
            campaign: angular.copy(campaign),
          },
          bindToController: true,
          controllerAs: 'formCtrl',
          fullscreen: true,
        })
        .then((savedCampaign) => {
          if (isNew) {
            $ctrl.campaigns.push(savedCampaign)
          } else {
            angular.copy(savedCampaign, campaign)
          }
          partitionCampaigns()
        })
    }

    function partitionCampaigns() {
      var partitions = {}
      $ctrl.campaigns.forEach((campaign) => {
        if (!partitions[campaign.type.id]) {
          partitions[campaign.type.id] = []
        }
        partitions[campaign.type.id].push(campaign)
      })
      $ctrl.campaignPartitions = partitions
    }
  }

  function CampaignFormController($filter, $mdDialog) {
    var $ctrl = this

    if (angular.isArray($ctrl.campaign.stores)) {
      $ctrl.campaign.stores = $ctrl.campaign.stores.map((store) => store.storeId)
    }

    $ctrl.dispatchMethods = {
      NL: 'Newsletter',
      PN: 'Push Benachrichtigung',
    }
    $ctrl.supportsFormData = Boolean(window.FormData)

    if ($ctrl.campaign.settings) {
      $ctrl.campaign.settings = $ctrl.campaign.settings.id
    }
    if ($ctrl.campaign.type) {
      $ctrl.campaign.type = $ctrl.campaign.type.id
    }
    if ($ctrl.campaign.startDate) {
      $ctrl.campaign.startDate = new Date($ctrl.campaign.startDate)
    }
    if ($ctrl.campaign.endDate) {
      $ctrl.campaign.endDate = new Date($ctrl.campaign.endDate)
    }

    $ctrl.cancel = (event) => {
      $mdDialog.cancel()
    }

    $ctrl.save = (event) => {
      var campaign = angular.copy($ctrl.campaign)
      campaign.customer = $ctrl.customer.customerId
      campaign.imageFile = $ctrl.campaign.imageFile // angular.copy can't copy File objects
      if (campaign.startDate) {
        campaign.startDate = $filter('date')(campaign.startDate)
      }
      if (campaign.endDate) {
        campaign.endDate = $filter('date')(campaign.endDate)
      }

      $ctrl.loading = true
      $ctrl
        .saveHandler(campaign)
        .then((response) => $mdDialog.hide(response))
        .finally(() => {
          $ctrl.loading = false
        })
    }
  }

  function CampaignSettingsListController($mdDialog, CRMCampaignService) {
    'ngInject'

    var $ctrl = this

    $ctrl.new = (event) => openFormDialog(event, {}, CRMCampaignService.createSettings)
    $ctrl.edit = (event, settings) => openFormDialog(event, settings, CRMCampaignService.updateSettings)
    $ctrl.delete = (event, settings) =>
      settings.$delete().then(() => {
        var index = $ctrl.settingsList.indexOf(settings)
        if (index > -1) {
          $ctrl.settingsList.splice(index, 1)
        }
      })

    function openFormDialog(event, settings, saveHandler) {
      var isNew = angular.equals(settings, {})
      $mdDialog
        .show({
          templateUrl: 'src/crm/views/dialog.campaign-settings.form.html',
          targetEvent: event,
          controller: CampaignSettingsFormController,
          locals: {
            customer: $ctrl.customer,
            settingsList: $ctrl.settingsList.filter((s) => s.id != settings.id),
            saveHandler: saveHandler,
            settings: angular.copy(settings),
          },
          bindToController: true,
          controllerAs: 'formCtrl',
          fullscreen: true,
          multiple: true,
        })
        .then((savedSettings) => {
          if (isNew) {
            $ctrl.settingsList.push(savedSettings)
          } else {
            angular.copy(savedSettings, settings)
          }
        })
    }
  }

  function CampaignSettingsFormController($filter, $mdDialog) {
    var $ctrl = this

    if ($ctrl.settings.closingTime) {
      $ctrl.settings.closingTime = new Date($ctrl.settings.closingTime)
    }

    $ctrl.cancel = (event) => {
      $mdDialog.cancel()
    }

    $ctrl.save = (event) => {
      var settings = angular.copy($ctrl.settings)
      settings.customer = $ctrl.customer.customerId

      $ctrl.loading = true
      $ctrl
        .saveHandler(settings)
        .then((response) => $mdDialog.hide(response))
        .finally(() => {
          $ctrl.loading = false
        })
    }

    $ctrl.weekdayOptions = $filter('range')([], 1, 35).reduce(function (options, day) {
      var ordinals = ['erster', 'zweiter', 'dritter', 'vierter', 'fünfter']
      var ordinal = ordinals[Math.ceil(day / 7) - 1]
      if (day % 7 == 0) {
        day -= 7
      }
      options.push({
        id: day,
        display:
          ordinal +
          ' ' +
          moment()
            .startOf('week')
            .add(day - 1, 'days')
            .format('dddd'),
      })
      return options
    }, [])
  }

  function CampaignTypeListController($mdDialog, CRMCampaignService) {
    'ngInject'

    var $ctrl = this

    $ctrl.new = (event) => openFormDialog(event, {}, CRMCampaignService.createType)
    $ctrl.edit = (event, type) => openFormDialog(event, type, CRMCampaignService.updateType)
    $ctrl.delete = (event, type) =>
      type.$delete().then(() => {
        var index = $ctrl.typeList.indexOf(type)
        if (index > -1) {
          $ctrl.typeList.splice(index, 1)
        }
      })

    function openFormDialog(event, type, saveHandler) {
      var isNew = angular.equals(type, {})
      $mdDialog
        .show({
          templateUrl: 'src/crm/views/dialog.campaign-type.form.html',
          targetEvent: event,
          controller: CampaignTypeFormController,
          locals: {
            customer: $ctrl.customer,
            saveHandler: saveHandler,
            type: angular.copy(type),
          },
          bindToController: true,
          controllerAs: 'formCtrl',
          fullscreen: true,
          multiple: true,
        })
        .then((savedType) => {
          if (isNew) {
            $ctrl.typeList.push(savedType)
          } else {
            angular.copy(savedType, type)
          }
        })
    }
  }

  function CampaignTypeFormController($mdDialog) {
    var $ctrl = this

    $ctrl.cancel = (event) => {
      $mdDialog.cancel()
    }

    $ctrl.save = (event) => {
      var type = angular.copy($ctrl.type)
      type.customer = $ctrl.customer.customerId

      $ctrl.loading = true
      $ctrl
        .saveHandler(type)
        .then((response) => $mdDialog.hide(response))
        .finally(() => {
          $ctrl.loading = false
        })
    }
  }
})()
